import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { FormsService } from '../../shared/services/forms.service';
import { AuthService } from '../../shared/services/auth.service';
import { ResourceService } from '../../shared/services/resource.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AssignResourceComponent } from '../assign-resource/assign-resource.component';
import { User } from '../../shared/models/user.model';

@Component({
  selector: 'app-delete-resource',
  templateUrl: './delete-resource.component.html',
  styleUrls: ['./delete-resource.component.css']
})
export class DeleteResourceComponent implements OnInit {

  constructor(
    private router: Router,
    public form: FormBuilder,
    public FormService: FormsService,
    private authService: AuthService,
    private resourceService: ResourceService,
    public dialogRef: MatDialogRef<DeleteResourceComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) { }

  ngOnInit() {
  console.log(this.data);
  }
  deleteResource(){
    this.resourceService.deleteResourceDetails(this.data.id).subscribe((res) => {
      console.log(res);
     console.log("Resource Details Deleted Successfully");
     this.dialogRef.close();
    });
  }
  close(){
    this.dialogRef.close();
  }

}
