import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '../../../../node_modules/@angular/forms';
import { FormsService } from '../../shared/services/forms.service';
import { RoleService } from '../../shared/services/role.service';
import { MatDialogRef } from '../../../../node_modules/@angular/material';
import { AddCountryComponent } from '../../country/add-country/add-country.component';
import { Router } from '../../../../node_modules/@angular/router';
import { ToastrService } from '../../../../node_modules/ngx-toastr';

@Component({
  selector: 'app-add-role',
  templateUrl: './add-role.component.html',
  styleUrls: ['./add-role.component.css']
})
export class AddRoleComponent implements OnInit {

  roleForm: FormGroup;
  public formErrors = {
    role: '',
  };

  constructor(
    private formBuilder: FormBuilder,
    public FormService: FormsService,
    private roleService: RoleService,
    public dialogRef: MatDialogRef<AddCountryComponent>,
    private route: Router,
    private toastr: ToastrService) { }

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.roleForm = this.formBuilder.group({
      role: new FormControl('', [Validators.required]),
    });
  }

  add(){
    this.FormService.markFormGroupTouched(this.roleForm);
    if (this.roleForm.valid) {
      this.roleService.addRole(this.roleForm.value).subscribe((res) => {
        this.dialogRef.close();
        console.log(res);
        this.toastr.success('Role added Successfully..!');
        this.route.navigate(['/list-role']);
    },
      error => {
      console.log(error.error);    
      this.toastr.error(error.error.error.name);
      }); 
    }
    else {
      this.formErrors = this.FormService.validateForm(this.roleForm, this.formErrors, false)
    }
  }

  reset(){
    this.roleForm.reset();
  }
  close(){
    this.dialogRef.close();
  }

}
