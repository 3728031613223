import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment.prod';

@Injectable()
export class SiteService {


  constructor( private http: HttpClient )  { }

  list(){
    const url = environment.apiUrl + "sites";
    return this.http.get(url);
  }

  getDocument(documentId){
    const url = environment.apiUrl +"templates/" + documentId;
    return this.http.get(url);
  }

  updateStatus(documentId,formData){
    const url = environment.apiUrl + "templates/"+ documentId + "/updateStatus";
    return this.http.post(url,formData);
  }

  addSite(formData){
    const url = environment.apiUrl + "sites";
    return this.http.post(url, formData );
  }

  getSitecontactdetails(SiteId){
    const url = environment.apiUrl +"sites/" + SiteId;
    return this.http.get(url);
  
}

//add site contact information api services
addSitecontact(site_details){
  const url = environment.apiUrl + "sites";
  return this.http.post(url, site_details );
}

//get site roles information api services
getSiteroles(){
  const url = environment.apiUrl +"siteroles" ;
  return this.http.get(url);

}


//get site contact information api services

getSiteContact(){
  const url = environment.apiUrl + "";
  return this.http.get(url);
}
}
