import { Component, OnInit } from '@angular/core';
import { UserTemplateService } from '../../shared/services/user-template.service';
import { AuthService } from '../../shared/services/auth.service';

@Component({
  selector: 'app-my-reports',
  templateUrl: './my-reports.component.html',
  styleUrls: ['./my-reports.component.css']
})
export class MyReportsComponent implements OnInit {
  templates:object = [];
  userTemplateId:any;
  userId:Object;

  constructor(private userTemplateService: UserTemplateService, private authService: AuthService) { }

  ngOnInit() {
    this.userId = this.authService.getCurrentUser().id;
    this.loadMyTemplates();
  }
  loadMyTemplates(){
    this.userTemplateService.list(this.userId).subscribe ( (res) =>{
      console.log("Templates" + res);      
      this.templates = res['templates'];
      
    });
  }

}
