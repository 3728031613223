import { Component, OnInit } from '@angular/core';
import { AddCityComponent } from '../add-city/add-city.component';
import { MatDialog } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { CountryService } from '../../shared/services/country.service';
import { EditCityComponent } from '../edit-city/edit-city.component';

@Component({
  selector: 'app-list-city',
  templateUrl: './list-city.component.html',
  styleUrls: ['./list-city.component.css']
})
export class ListCityComponent implements OnInit {

  pageLimit = 10;
  pageLimitValues = [
    { value: 10 },
    { value: 20 },
    { value: 30 },
    { value: 40 },
  ];
  searchText: string;
  filteredItems: any = [];
  citylist: any = [];
  id:number;

  constructor(private dialog: MatDialog,private router: Router,private countryService: CountryService, private route : ActivatedRoute) {
    route.params.subscribe ( (params) =>{
      this.id = params['id'];
      console.log("id:" + this.id );
    });
   }
   
  ngOnInit() {
    this.getCityList();
  }

  pageLimitChange(pageLimitValue) {
    this.pageLimit = pageLimitValue;
  }

  assignfilterItems() {
    this.filteredItems = Object.assign([], this.citylist);
  }

  filterItem() {
    if (!this.searchText) this.assignfilterItems(); //when nothing has typed
    this.filteredItems = Object.assign([], this.citylist).filter(
      item => item.name.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1
    )
  }

  openAddCity(){
    const dialogRef = this.dialog.open(AddCityComponent,{
      width :  '980px',
      data:this.id
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getCityList();
    });
   }

   openUpdatedCity(c){
    const dialogRef = this.dialog.open(EditCityComponent,{
      width :  '980px',
      data: c
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getCityList();
    });
   }
  
   getCityList() {
    this.countryService.getCity(this.id).subscribe((res) => {
      console.log(res);
      this.citylist = res;
      this.assignfilterItems();
    });

  }
}