import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../shared/services/auth.service';
import { ProjectService } from '../../shared/services/project.service';
import { ResourceService } from '../../shared/services/resource.service';
import { CodeService } from '../../shared/services/code.service';
import { CroService } from '../../shared/services/cro.service';
import { Validators, FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { User } from '../../shared/models/user.model';
import { FormsService } from '../../shared/services/forms.service';

@Component({
  selector: 'app-assign-resource',
  templateUrl: './assign-resource.component.html',
  styleUrls: ['./assign-resource.component.css']
})
export class AssignResourceComponent implements OnInit {

  assignResourceForm:FormGroup;
  loggedInUser: User;
  resourceTypes = [
    {value: 'CRO', viewValue: 'CRO'},
    {value: 'SITE', viewValue: 'SITE'},
    {value: 'SPONSOR', viewValue: 'SPONSOR'}
  ];

  Roles = [
    {value: 'Role 1', viewValue: 'Role 1'},
    {value: 'Role 2', viewValue: 'Role 2'},
    {value: 'Role 3', viewValue: 'Role 3'}
  ];

  public formErrors = {
    project: '',
    mappingType:'',
    cro:'',
    role:'',
    resource:''
  
  };
  
  
  projectMap:any = {};
  projectList:any = [];
  crolist:any = [];
  countryList: any = [];
  resourceList:any = [];


  constructor( 
    private router: Router,
    public form: FormBuilder,
    public FormService: FormsService,
    private authService: AuthService,
    private projectService: ProjectService,
    private resourceService: ResourceService,
    private croService : CroService,
    private codeService: CodeService,
    public dialogRef: MatDialogRef<AssignResourceComponent>,
  ) { }

  ngOnInit() {
    this.loadProjectList();
    this.loadCroList();
    this.loadCountryList();
    this.loadResources();
    this.buildForm();
    this.loggedInUser = this.authService.getCurrentUser();
    }

    public buildForm() {
      this.assignResourceForm = this.form.group({
        project: ['', [Validators.required]],
        mappingType:['', [Validators.required]],
        cro:['', [Validators.required]],
        role:['', [Validators.required]],
        resource:['', [Validators.required]],
      });
      this.assignResourceForm.valueChanges.subscribe((data) => {
        this.formErrors = this.FormService.validateForm(this.assignResourceForm, this.formErrors, false)
      });
    }
  
    loadResources() {
      this.resourceService.list().subscribe((res) => {
        console.log(res);
        this.resourceList = res;
      });
    }
  
    loadProjectList() {
      this.projectService.list().subscribe((res) => {
        console.log(res);
        this.projectList = res;
      });
    }
    
    loadCroList() {
      this.croService.list().subscribe((res) => {
        console.log(res);
        this.crolist = res;
      });
    }
    loadCountryList() {
      this.codeService.getCountry().subscribe((res) => {
        console.log(res);
        this.countryList = res;
      });
    }

    assignResource(){
      this.FormService.markFormGroupTouched(this.assignResourceForm);
    if (this.assignResourceForm.valid) {
      this.assignResourceForm.setControl('createdBy', new FormControl(this.loggedInUser.id));
      console.log(this.assignResourceForm.value);
      this.dialogRef.close();
    }
    else {
      this.formErrors = this.FormService.validateForm(this.assignResourceForm, this.formErrors, false)
    }
   }
   close(){
    this.dialogRef.close();
  }

}
