import { Component, OnInit, Inject } from '@angular/core';
import { FormsService } from '../../shared/services/forms.service';
import { AuthService } from '../../shared/services/auth.service';
import { SiteService } from '../../shared/services/site.service';
import { Router } from '@angular/router';
import { User } from '../../shared/models/user.model';
// import { AddProjectComponent } from '../../projects/add-project/add-project.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { CustomValidators } from '../../shared/services/custom-validators.service';
import { CodeService } from '../../shared/services/code.service';


@Component({
  selector: 'app-add-site',
  templateUrl: './add-site.component.html',
  styleUrls: ['./add-site.component.css']
})
export class AddSiteComponent implements OnInit {

  phoneCode: any;
  loggedInUser: User;
  siteForm: FormGroup;
  countries: Object = [];
  states: Object = [];


  constructor(
    public form: FormBuilder,
    public FormService: FormsService,
    private authService: AuthService,
    private siteService: SiteService,
    private codeService: CodeService,
    private router: Router,
    public dialogRef: MatDialogRef<AddSiteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  public formErrors = {
    croName: '',
    croNo: '',
    addressLine1: '',
    addressLine2: '',
    country: '',
    stateCode: '',
    zipCode: '',
    personName: '',
    countryTelephoneCode: '',
    telephoneNo: '',
    mobileNo: '',
    email: '',
  };

  ngOnInit() {
    this.buildForm();
    this.loadCountries();
    this.loggedInUser = this.authService.getCurrentUser();
  }


  public buildForm() {
    this.siteForm = this.form.group({
      siteName: ['', [Validators.required]],
      siteNo: ['', [Validators.required]],
      address: this.form.group({
        addressLine1: ['', [Validators.required]],
        addressLine2: ['', [Validators.required]],
        countryCode: ['', [Validators.required]],
        stateCode: ['', [Validators.required]],
        zipCode: ['', [Validators.required]],
      }),
      contact: this.form.group({
        personName: ['', [Validators.required]],
        countryTelephoneCode: ['', [Validators.required]],
        telephoneNo: ['', [Validators.required]],
        mobileNo: ['', [Validators.required]],
        email: ['', [Validators.required]],
      })
    });

    this.siteForm.valueChanges.subscribe((data) => {
      this.formErrors = this.FormService.validateForm(this.siteForm, this.formErrors, true)
    });
  }
  addSite() {
    this.FormService.markFormGroupTouched(this.siteForm);
    if (this.siteForm.valid) {
      this.siteForm.setControl('createdBy', new FormControl(this.loggedInUser.id));
      console.log(this.siteForm.value);
      this.siteService.addSite(this.siteForm.value).subscribe((res) => {
        this.siteForm.reset();
        this.dialogRef.close();
      },
        error => {
          console.log(error.error.message);
        });
    }
    else {
      this.formErrors = this.FormService.validateForm(this.siteForm, this.formErrors, false)
    }
  }

  loadCountries() {
    this.codeService.getCountry().subscribe((res) => {
      this.countries = res;
      console.log(res);
    });
  }

  selectedCountry(countryName) {
    console.log('load states:' + countryName);
    this.codeService.getState(countryName).subscribe((res) => {
      this.states = res;
      console.log(res);
    });
    this.codeService.getPhoneCode(countryName).subscribe((res) => {
      this.phoneCode = res[0].description;
      this.phoneCode = '+' + this.phoneCode;
      console.log(res[0].description);
    });
  }
  resetForm() {
    this.siteForm.reset();
  }

  close() {
    this.dialogRef.close();
  }

}
