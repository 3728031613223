import { Component, OnInit } from '@angular/core';
import { User } from '../../shared/models/user.model';
import { TemplateService } from '../../shared/services/template.service';
import { AuthService } from '../../shared/services/auth.service';
import { UserTemplateService } from '../../shared/services/user-template.service';
import { ActivatedRoute, Router } from '@angular/router';
import { QuestionService } from '../../shared/services/question.service';
import { VerifyReportComponent } from '../verify-report/verify-report.component';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material';


@Component({
  selector: 'app-edit-questions',
  templateUrl: './edit-questions.component.html',
  styleUrls: ['./edit-questions.component.css']
})
export class EditQuestionsComponent implements OnInit {
  loggedInUser: User;
  id:Number;
  templateQuestions:any = {};

  constructor(private authService:AuthService,private dialog: MatDialog,private UserTemplateService: UserTemplateService,private route:ActivatedRoute) {

    route.params.subscribe ( (params) =>{
      this.id = params['id'];
      console.log("id:" + this.id );
    });
   }

  ngOnInit() {
    this.loggedInUser = this.authService.getCurrentUser();
    this.loadTemplateQuestions();
  }
  loadTemplateQuestions(){    
    this.UserTemplateService.getTemplateQuestions(this.id).subscribe( (res) => {
    console.log(res);  
    this.templateQuestions = res;
   });  
   }
   saveQuestions(){
    this.templateQuestions.status = "SAVED";
    console.log(this.templateQuestions);
    this.UserTemplateService.submitAnswer(this.id, this.templateQuestions).subscribe( (res) => {
      console.log(res);  
    });
    console.log(this.templateQuestions);
  }

  openVerifyTemplates(){
    const dialogRef = this.dialog.open(VerifyReportComponent,{
      height : 'auto',
      width :  '980px',
      data: this.templateQuestions
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
          
    });

  }
}
