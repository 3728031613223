import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class CountryService {

  constructor(private http: HttpClient) { }

  addCountry(formData){
    const url = environment.apiUrl + "Countries";
    return this.http.post(url, formData );
  }
  updateCountry(formData, id){
    const url = environment.apiUrl + "Countries/"+id;
    return this.http.patch(url, formData );
  }
  getCountry(){
    const url = environment.apiUrl + "Countries";
    return this.http.get(url);
  }

  addState(formData){
    const url = environment.apiUrl + "States";
    return this.http.post(url, formData );
  }
  updateState(formData, id){
    const url = environment.apiUrl + "States/"+ id;
    return this.http.patch(url, formData );
  }
  getState(id){
    const url = environment.apiUrl +'Countries/'+id +"/country_state";
    return this.http.get(url);
  }

  addCity(formData){
    const url = environment.apiUrl + "Cities";
    return this.http.post(url, formData );
  }
  updateCity(formData, id){
    const url = environment.apiUrl + "Cities/"+ id;
    return this.http.patch(url, formData );
  }
  getCity(id){
    const url = environment.apiUrl +'States/'+id+ "/state_city";
    return this.http.get(url);
  }

}
