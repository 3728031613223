import { Component, OnInit } from '@angular/core';
import { AddStateComponent } from '../add-state/add-state.component';
import { MatDialog } from '../../../../node_modules/@angular/material';
import { Router, ActivatedRoute } from '../../../../node_modules/@angular/router';
import { CountryService } from '../../shared/services/country.service';
import { EditStateComponent } from '../edit-state/edit-state.component';

@Component({
  selector: 'app-list-state',
  templateUrl: './list-state.component.html',
  styleUrls: ['./list-state.component.css']
})
export class ListStateComponent implements OnInit {

  pageLimit = 10;
  pageLimitValues = [
    { value: 10 },
    { value: 20 },
    { value: 30 },
    { value: 40 },
  ];
  searchText: string;
  filteredItems: any = [];
  statelist: any = [];
  id:number;

  constructor(private dialog: MatDialog,private router: Router,private countryService: CountryService, private route : ActivatedRoute) {
    route.params.subscribe ( (params) =>{
      this.id = params['id'];
      console.log("id:" + this.id );
    });
   }

  ngOnInit() {
    this.getStateList();
  }

  pageLimitChange(pageLimitValue) {
    this.pageLimit = pageLimitValue;
  }

  assignfilterItems() {
    this.filteredItems = Object.assign([], this.statelist);
  }

  filterItem() {
    if (!this.searchText) this.assignfilterItems(); //when nothing has typed
    this.filteredItems = Object.assign([], this.statelist).filter(
      item => item.name.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1
    )
  }

  openAddState(){
    const dialogRef = this.dialog.open(AddStateComponent,{
      width :  '980px',
      data:this.id
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getStateList();
    });
   }
  
   openUpdatedState(s){
    const dialogRef = this.dialog.open(EditStateComponent,{
      width :  '980px',
      data: s
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getStateList();
    });
   }

   getStateList() {
    this.countryService.getState(this.id).subscribe((res) => {
      console.log(res);
      this.statelist = res;
      this.assignfilterItems();
    });
  }

  getCitiesByState(id){
    console.log("id", id);
    this.router.navigate(['/list-city/'+id]);
  }
} 