import { Injectable } from '@angular/core';
import { HttpClient ,HttpHeaders} from '@angular/common/http';
import { environment } from '../../../environments/environment.prod';
import { AuthService } from './auth.service';


@Injectable()
export class ProjectService {

  loggedInUser:any= {};
  constructor(private authService: AuthService, private http :HttpClient){

    this.loggedInUser = this.authService.getCurrentUser();
  }
  
  getAssignedSites(arg0: any): any {
    throw new Error("Method not implemented.");
  }

  addProject(formData){
    const url = environment.apiUrl + "projects";
    return this.http.post(url, formData );
  }

  getUsers(projectId){
    const url =  environment.apiUrl + "projects/" + projectId + "/users";
    return this.http.get(url);
  }


  list(){
    let h = new HttpHeaders();    
    h.set('Authorization','Bearer '+ this.loggedInUser.id);  
    console.log(h);  
    const url = environment.apiUrl + "projects";
    return this.http.get(url, {headers: h});
  }

  getProjectDetails(projectId){
    const url = environment.apiUrl +"projects/" + projectId;
    return this.http.get(url);  
}
  getAssignedSitesList(projectId){
  const url = environment.apiUrl +"projects/" + projectId + "/sites" ;
    return this.http.get(url);
}

getAllSitesList(projectId){
  const url = environment.apiUrl + "projects/" + projectId + "/sites/available";
    return this.http.get(url);
}


assignSites(projectId, formaData){
  const url = environment.apiUrl +"projects/" + projectId + "/sites";
  return this.http.post(url, formaData);
}

}
