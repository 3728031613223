import { Component, OnInit } from '@angular/core';
import { ProjectService } from '../../shared/services/project.service';
import { AddProjectComponent } from '../add-project/add-project.component';
import { MatDialog } from '@angular/material';
//import { AddProjectComponent } from '../add-project/add-project.component';

@Component({
  selector: 'app-list-projects',
  templateUrl: './list-projects.component.html',
  styleUrls: ['./list-projects.component.css']
})
export class ListProjectsComponent implements OnInit {

  searchText:string;
  pageLimit = 10;
  pageLimitValues = [
    { value: 5 },
    { value: 10 },
    { value: 20 },
    { value: 30 },
    { value: 40 },
  ];
  filteredItems:any =[];
  projectList:any = [];

  constructor(
    private dialog: MatDialog,
    private projectService: ProjectService
  ) { }

  ngOnInit() {
    this.loadProjects();
  }

  loadProjects() {
    this.projectService.list().subscribe((res) => {
      console.log(res);
      this.projectList = res;
      this.assignfilterItems();
    });
  }
  pageLimitChange(pageLimitValue){
    this.pageLimit = pageLimitValue;
  }
  assignfilterItems(){
    this.filteredItems = Object.assign([], this.projectList);
  }
  filterItem(){
    if(!this.searchText) this.assignfilterItems(); //when nothing has typed
    this.filteredItems = Object.assign([], this.projectList).filter(
    
      item => item.sponsorName.toLowerCase().indexOf(this.searchText.toLowerCase())  > -1
             
      //item2 => item.projectName.toLowerCase().indexOf(this.searchText.toLowerCase())  > -1
    )
 }

  openDialog() {
    const dialogRef = this.dialog.open(AddProjectComponent,{
      height : '600px',
      width :  '980px'
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      this.loadProjects();  
         
    });
  }

}
