import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { CustomValidators } from '../../shared/services/custom-validators.service';
import { FormsService } from '../../shared/services/forms.service';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../shared/services/auth.service';


@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {

  public signUpForm: FormGroup;
  public formErrors = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
  };

  constructor(
    public form: FormBuilder,
    public FormService: FormsService,
    public snackbar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,


  ) { }

  public signUp() {
    this.FormService.markFormGroupTouched(this.signUpForm);
    if (this.signUpForm.valid) {
      this.authService.register(this.signUpForm.value)
        .subscribe(
          data => {

            this.router.navigate(['/login']);
          },
          error => {

            console.log(error);
            this.snackbar.open('Error!', 'Close', {
              duration: 3000,
            });
          });
      //this.signUpForm.reset();
    } else {
      this.formErrors = this.FormService.validateForm(this.signUpForm, this.formErrors, false)
    }
  }

  // build the user edit form
  public buildForm() {
    this.signUpForm = this.form.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
      accessRequired: ['true']

    });
    // on each value change we call the validateForm function
    // We only validate form controls that are dirty, meaning they are touched
    // the result is passed to the formErrors object
    this.signUpForm.valueChanges.subscribe((data) => {
      this.formErrors = this.FormService.validateForm(this.signUpForm, this.formErrors, true)
    });
  }

  // initiate component
  ngOnInit() {
    this.buildForm();

  }

}
