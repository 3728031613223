import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { User } from '../models/user.model';

import {map, catchError} from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  currentUser: User;

  // store the URL so we can redirect after logging in
  redirectUrl: string;

  constructor(private http: HttpClient, private router: Router) {

  }

  login(user_details) {

    console.log(user_details);
     // var user_details = { "email": username, "password": password };
      return this.http.post(environment.apiUrl + 'AppUsers/login', user_details)
          .pipe(map(user => {                
              localStorage.setItem('userDetails', JSON.stringify(user));
              this.currentUser = this.getCurrentUser();
              return user;
          }),
      catchError(error => of(null)));
  }

  verify(user_details) {
      return this.http.post(environment.apiUrl + 'users/login', user_details);
  }

  getCurrentUser(){
      var obj = localStorage.getItem('userDetails');
      if(obj!=null){
          var user = JSON.parse(obj);
          return new User(user["id"],user["name"],user["email"], user["active"]);
      }
      return null;
  }

  register(user_details) {
      const url = environment.apiUrl + "users/register";
      return this.http.post(url, user_details);
  }


  isLoggedIn() {
      var obj = localStorage.getItem('userDetails');
      return obj != null;
  }

  logout() {
      console.log("AuthService: logout");
      localStorage.clear();
      this.currentUser = null;
     // this.router.navigateByUrl('/login');
      this.router.navigate(['/login']);

  }

  resetPassword(email){
    
    const url = environment.apiUrl + "users/resetPassword";
    return this.http.post(url, email);
  }

  setPassword(userObj){
      const url = environment.apiUrl + "users/setPassword";
      return this.http.post(url,userObj);

  }
}
