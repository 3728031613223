import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AddSponsorComponent } from './sponsor/add-sponsor/add-sponsor.component';
import { ListSponsorComponent } from './sponsor/list-sponsor/list-sponsor.component';
import { AuthModule } from './auth/auth.module';
import { AuthGuard } from './auth.gurad';
import { AuthLayoutComponent } from './auth-layout/auth-layout.component';
import { ListProjectsComponent } from './projects/list-projects/list-projects.component';
import { ProjectSiteListComponent } from './projects/project-site-list/project-site-list.component';
import { ListResourceComponent } from './resource/list-resource/list-resource.component';
import { ListSitesComponent } from './sites/list-sites/list-sites.component';
import { ListCroComponent } from './cro/list-cro/list-cro.component';
import { ProfileComponent } from './profile/profile.component';
import { UserReportsComponent} from './user-reports/user-reports.component';
import { AssignedReportsComponent } from './user-reports/assigned-reports/assigned-reports.component';
import { AssignReportComponent } from './user-reports/assign-report/assign-report.component';
import { MyReportsComponent } from './user-reports/my-reports/my-reports.component';
import { ViewReportsComponent } from './user-reports/view-reports/view-reports.component';
import { EditQuestionsComponent } from './user-reports/edit-questions/edit-questions.component';
import { AddCountryComponent } from './country/add-country/add-country.component';
import { ListCountyComponent } from './country/list-county/list-county.component';
import { ListStateComponent } from './state/list-state/list-state.component';
import { ListCityComponent } from './city/list-city/list-city.component';
import { ListTherapeuticAreaComponent } from './therapeutic-area/list-therapeutic-area/list-therapeutic-area.component';
import { AddStateComponent } from './state/add-state/add-state.component';
import { EditStateComponent } from './state/edit-state/edit-state.component';
import { ListRoleComponent } from './role/list-role/list-role.component';
import { EditCountryComponent } from './country/edit-country/edit-country.component';



const routes: Routes = [

  {
    path: '',
    loadChildren: () => AuthModule,
    component: AuthLayoutComponent
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'dashboard', component: DashboardComponent },
      { path: 'profile', component: ProfileComponent },
      { path: 'create-sponsor', component: AddSponsorComponent },
      { path: 'sponsor', component: ListSponsorComponent },
      { path: 'resources', component: ListResourceComponent },
      { path: 'projects', component: ListProjectsComponent},
      { path: 'projects/:id', component: ProjectSiteListComponent},
      { path: 'sites', component: ListSitesComponent},
      { path: 'cro', component: ListCroComponent},
      { path: 'resource', component: ListResourceComponent },
      { path: 'user-reports', component:UserReportsComponent},
      { path: 'my-reports', component:MyReportsComponent},
      { path: 'assigned-reports', component:AssignedReportsComponent},
      { path: 'assign-reports', component:AssignReportComponent}, 
      { path: 'view-reports/:id', component:ViewReportsComponent},
      { path: 'usertemplates/:id', component: EditQuestionsComponent },
      { path: 'add-country', component:AddCountryComponent },
      { path: 'list-country', component: ListCountyComponent},
      { path: 'list-country/:id', component:EditCountryComponent},
      { path: 'list-state/:id', component:ListStateComponent},
      { path: 'add-state', component: AddStateComponent},
      { path: 'list-city/:id', component: ListCityComponent},
      { path: 'list-role', component: ListRoleComponent},
      { path: 'list-therapeutic-area', component:ListTherapeuticAreaComponent}    

    ]
  },
  { path: '**', redirectTo: '' }
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
