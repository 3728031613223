import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { CustomValidators } from '../../shared/services/custom-validators.service';
import { FormsService } from '../../shared/services/forms.service';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../shared/services/auth.service';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.css']
})
export class SetPasswordComponent implements OnInit {

  activationKey:string;
  public setPasswordForm: FormGroup;
  public formErrors = {
    password: ''
  };

  userObj: any = {};


  constructor(
    private authService: AuthService, 
    private route: ActivatedRoute,
    private router: Router,
    public form: FormBuilder,
    public FormService: FormsService,
    public snackbar: MatSnackBar
   ) { 
    this.route.params.subscribe( (params) => {
      this.activationKey = params['activationKey'];
      this.userObj["activationKey"] = this.activationKey;
    })

  }

  ngOnInit() {
  this.buildForm();
  }

  buildForm() {
    this.setPasswordForm = this.form.group({
      password: ['', [Validators.required]]
    });
    this.setPasswordForm.valueChanges.subscribe((data) => {
      this.formErrors = this.FormService.validateForm(this.setPasswordForm, this.formErrors, true)
    });
  }

  setPassword(){
    this.FormService.markFormGroupTouched(this.setPasswordForm);
    if (this.setPasswordForm.valid) {
      this.authService.setPassword(this.setPasswordForm.value)
      .subscribe(
        data => {

          this.router.navigate(['/login']);           
        },
        error => {

          this.snackbar.open('Error!', 'Close', {
            duration: 3000,
          });
          console.log(error);
         
        });
      //this.signUpForm.reset();
    } else {
      this.formErrors = this.FormService.validateForm(this.setPasswordForm, this.formErrors, false)
    }
  }

}
