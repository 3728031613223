import { Component, OnInit } from '@angular/core';
import { AddTherapeuticAreaComponent } from '../add-therapeutic-area/add-therapeutic-area.component';
import { MatDialog } from '../../../../node_modules/@angular/material';
import { Router } from '../../../../node_modules/@angular/router';
import { TherapeticService } from '../../shared/services/therapetic.service';
import { EditTherapeuticAreaComponent } from '../edit-therapeutic-area/edit-therapeutic-area.component';

@Component({
  selector: 'app-list-therapeutic-area',
  templateUrl: './list-therapeutic-area.component.html',
  styleUrls: ['./list-therapeutic-area.component.css']
})
export class ListTherapeuticAreaComponent implements OnInit {

  pageLimit = 10;
  pageLimitValues = [
    { value: 10 },
    { value: 20 },
    { value: 30 },
    { value: 40 },
  ];
  searchText: string;
  filteredItems: any = [];
  therapeuticlist: any = [];

  constructor(private dialog: MatDialog,private router: Router,private therapeuticService: TherapeticService) { }

  ngOnInit() {
    this.getTherapeuticList();
  }

  pageLimitChange(pageLimitValue) {
    this.pageLimit = pageLimitValue;
  }

  assignfilterItems() {
    this.filteredItems = Object.assign([], this.therapeuticlist);
  }

  filterItem() {
    if (!this.searchText) this.assignfilterItems(); //when nothing has typed
    this.filteredItems = Object.assign([], this.therapeuticlist).filter(
      item => item.croName.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1
    )
  }

  openAddTherapeutic(){
    const dialogRef = this.dialog.open(AddTherapeuticAreaComponent,{
      width :  '980px',
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getTherapeuticList();
    });
   }

   openEditTherapeutic(t){
    const dialogRef = this.dialog.open(EditTherapeuticAreaComponent,{
      width :  '980px',
      data: t
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getTherapeuticList();
    });
   }
  
   getTherapeuticList() {
    this.therapeuticService.getTherapeticArea().subscribe((res) => { 
      console.log(res);
      this.therapeuticlist = res;
      this.assignfilterItems();
    });

  }
}

