import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router) {
    console.log("Auth Guard - constructor");
   }
  
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      console.log('AuthGuard called');
      if (localStorage.getItem('userDetails')) {
        // logged in so return true
        return true;
    }
    // not logged in so redirect to login page with the return url { queryParams: { returnUrl: state.url }}
    console.log('Redirect to /auth')
    this.router.navigate(['/auth'], { queryParams: { returnUrl: state.url }});
    return false;    
  }
}
