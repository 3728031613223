import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { CustomValidators } from '../../shared/services/custom-validators.service';
import { FormsService } from '../../shared/services/forms.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../shared/services/auth.service';
import { User } from '../../shared/models/user.model';
import { UserTemplateService } from '../../shared/services/user-template.service'

@Component({
  selector: 'app-verify-report',
  templateUrl: './verify-report.component.html',
  styleUrls: ['./verify-report.component.css']
})
export class VerifyReportComponent implements OnInit {

  public confirmationReportForm: FormGroup;
  loggedInUser:User;
  id:Number;

  public formErrors = {
    email: '',
    password: '',
  };


  constructor(public form: FormBuilder,
    public FormService: FormsService,
    private route: ActivatedRoute,
    private router: Router,private authService: AuthService,
    private userTemplateService: UserTemplateService,
    public dialogRef: MatDialogRef<VerifyReportComponent>,
    @Inject(MAT_DIALOG_DATA) public formObj: any
  ) { 
    route.params.subscribe ( (params) =>{
      this.id = params['id'];
      console.log("id:" + this.id );
    });
  }

  ngOnInit() {
    this.buildForm();
    this.loggedInUser = this.authService.getCurrentUser();
  }
  public buildForm() {
    this.confirmationReportForm = this.form.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });
    this.confirmationReportForm.valueChanges.subscribe((data) => {
      this.formErrors = this.FormService.validateForm(this.confirmationReportForm, this.formErrors, true)
    });
  }

  verifyAccount() {
    this.FormService.markFormGroupTouched(this.confirmationReportForm);
    if (this.confirmationReportForm.valid) {
      this.authService.verify(this.confirmationReportForm.value)
      .subscribe(
        data => {
          this.formObj.status = "SUBMITTED";
          console.log(this.formObj);
          this.userTemplateService.submitAnswer(this.formObj.id, this.formObj).subscribe( (res) => {
             console.log(res);
             this.router.navigate(['/my-reports']);
             this.dialogRef.close();
             
          });         
          },
        error => {

          console.log(error);
         
        });
      //this.signUpForm.reset();
    } else {
      this.formErrors = this.FormService.validateForm(this.confirmationReportForm, this.formErrors, false)
    }
  }
  close(){
    this.dialogRef.close();
  }
  

}
