import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { FormsService } from '../../shared/services/forms.service';
import { MatDialogRef } from '@angular/material';
import { CountryService } from '../../shared/services/country.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-country',
  templateUrl: './add-country.component.html',
  styleUrls: ['./add-country.component.css']
})
export class AddCountryComponent implements OnInit {

  countryForm: FormGroup;
  public formErrors = {
    name: '',
    country_mobile_code: '',
  };

  constructor(
    private formBuilder: FormBuilder,
    public FormService: FormsService,
    private countryService: CountryService,
    public dialogRef: MatDialogRef<AddCountryComponent>,
    private route: Router,
    private toastr: ToastrService) { }

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.countryForm = this.formBuilder.group({
      name: new FormControl('', [Validators.required]),
      country_mobile_code: new FormControl('', [Validators.required]),
    });
  }

  add(){
    this.FormService.markFormGroupTouched(this.countryForm);
    if (this.countryForm.valid) {
      this.countryService.addCountry(this.countryForm.value).subscribe((res) => {
        this.dialogRef.close();
        console.log(res);
        this.toastr.success('Country added Successfully..!');
        this.route.navigate(['/list-country']);
    },
      error => {
      console.log(error.error);    
      this.toastr.error(error.error.error.name);
      }); 
    }
    else {
      this.formErrors = this.FormService.validateForm(this.countryForm, this.formErrors, false)
    }
  }

  reset(){
    this.countryForm.reset();
  }
  close(){
    this.dialogRef.close();
  }

}
