import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { CustomValidators } from '../../shared/services/custom-validators.service';
import { FormsService } from '../../shared/services/forms.service';
import { AuthService } from '../../shared/services/auth.service';
import { User } from '../../shared/models/user.model';
import { ResourceService } from '../../shared/services/resource.service';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';



@Component({
  selector: 'app-add-resource',
  templateUrl: './add-resource.component.html',
  styleUrls: ['./add-resource.component.css']
})
export class AddResourceComponent implements OnInit {

  resourceForm:FormGroup;
  loggedInUser: User;
  resourceTypes = [
    {value: 'CRO', viewValue: 'CRO'},
    {value: 'SITE', viewValue: 'SITE'},
    {value: 'SPONSOR', viewValue: 'SPONSOR'}
  ];

  public formErrors = {
    firstName: '',
    lastName:'',
    mobileNo:'',
    email:'',
    resourceAt:''
  };
  
  constructor(
    public form: FormBuilder,
    public FormService: FormsService,
    private authService: AuthService,
    private resourceServices: ResourceService,
    private router: Router,   
    public dialogRef: MatDialogRef<AddResourceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
  this.buildForm();
  this.loggedInUser = this.authService.getCurrentUser();

  }

  public buildForm() {
    this.resourceForm = this.form.group({
      firstName: ['', [Validators.required]],
      lastName:['', [Validators.required]],
      mobileNo:['', [Validators.required]],
      email:['', [Validators.required, Validators.email]],
      resourceAt:['', [Validators.required]],
      status:['true'],
    });
    this.resourceForm.valueChanges.subscribe((data) => {
      this.formErrors = this.FormService.validateForm(this.resourceForm, this.formErrors, true)
    });
  }

  addResource() {
    this.FormService.markFormGroupTouched(this.resourceForm);
    if (this.resourceForm.valid) {
      this.resourceForm.setControl('createdBy', new FormControl(this.loggedInUser.id));
      this.resourceServices.addresourceDetails(this.resourceForm.value).subscribe((res) => {
      this.resourceForm.reset();     
      this.dialogRef.close();
    },
      error => {
      console.log(error.error.message);
      //this.alertService.danger(error.error.message);
      }); 
    }
    else {
      this.formErrors = this.FormService.validateForm(this.resourceForm, this.formErrors, false)
    }
  }

resetResource(){
  this.dialogRef.close();
}

}
