import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { CustomValidators } from '../../shared/services/custom-validators.service';
import { FormsService } from '../../shared/services/forms.service';
import { AuthService } from '../../shared/services/auth.service';
import { User } from '../../shared/models/user.model';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ProjectService } from '../../shared/services/project.service';
import { TemplateService } from '../../shared/services/template.service';
import { UserTemplateService } from '../../shared/services/user-template.service';
import { ResourceService } from '../../shared/services/resource.service';
import * as _ from "underscore";



@Component({
  selector: 'app-assign-report',
  templateUrl: './assign-report.component.html',
  styleUrls: ['./assign-report.component.css']
})
export class AssignReportComponent implements OnInit {
  users: any;
  projects: Object;
  assignReportForm: FormGroup;
  projectId: any;
  templateId: any;
  userId: any;
  loggedInUser: User;
  templates: Object = [];
  userTemplate: Object = { "projectId": "", "templateId": "", "userId": "", "createdBy": "" };


  public formErrors = {
    selectProject: '',
    selectReport: '',
    assignedTo: ''

  };

  constructor(private authService: AuthService, private projectservice: ProjectService, public form: FormBuilder,
    public FormService: FormsService, private router: Router, private templateService: TemplateService, private userTemplateService: UserTemplateService, private resourceService: ResourceService, public dialogRef: MatDialogRef<AssignReportComponent>, ) { }

  ngOnInit() {
    this.loggedInUser = this.authService.getCurrentUser();
    this.loadProjects();
    this.buildForm();
    this.loadUsers();
    this.loadTemplates();

  }

  public buildForm() {
    this.assignReportForm = this.form.group({

      projectId: ['', [Validators.required]],
      templateId: ['', [Validators.required]],
      userId: ['', [Validators.required]],
    });
    this.assignReportForm.valueChanges.subscribe((data) => {
      this.formErrors = this.FormService.validateForm(this.assignReportForm, this.formErrors, true)
    });
  }

  loadProjects() {
    this.projectservice.list().subscribe((res) => {
      this.projects = res;
      console.log(res);
    });
  }

  loadUsers() {
    this.resourceService.list().subscribe((res: Object[]) => {
      var userTemp = res;
      this.users = _.where(userTemp, { "resourceAt": "CRO" });
    })
  }

  loadTemplates() {
    this.templateService.list().subscribe((res: Object[]) => {
      var temp = res;
      this.templates = _.where(temp, { "status": "Approved" });
    });
  }

  assignTemplate() {  
    this.FormService.markFormGroupTouched(this.assignReportForm);
    if (this.assignReportForm.valid) {
      this.assignReportForm.setControl('createdBy', new FormControl(this.loggedInUser.id));
      this.userTemplateService.save(this.assignReportForm.value).subscribe((res) => {
     
      this.dialogRef.close();
    },
      error => {
      console.log();
      //this.alertService.danger(error.error.message);
      }); 
    }
    else {
      this.formErrors = this.FormService.validateForm(this.assignReportForm, this.formErrors, false)
    }
  }

  private newMethod(error: any): any {
    return error.error.message;
  }

  resetResource() {
    this.dialogRef.close();
  }


}
