import { Component, OnInit, Inject } from '@angular/core';
import { Validators, FormControl, FormBuilder, FormGroup } from '../../../../node_modules/@angular/forms';
import { FormsService } from '../../shared/services/forms.service';
import { CountryService } from '../../shared/services/country.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '../../../../node_modules/@angular/material';
import { Router } from '../../../../node_modules/@angular/router';
import { ToastrService } from '../../../../node_modules/ngx-toastr';

@Component({
  selector: 'app-edit-city',
  templateUrl: './edit-city.component.html',
  styleUrls: ['./edit-city.component.css']
})
export class EditCityComponent implements OnInit {

  updateForm: FormGroup;
  public formErrors = {
    name: '',
    state_id:''
  };

  constructor(
    private formBuilder: FormBuilder,
    public FormService: FormsService,
    private countryService: CountryService,
    private route: Router,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<EditCityComponent>,
    @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit() {
    this.buildForm();
    console.log(this.data);
  }

  buildForm() {
    this.updateForm = this.formBuilder.group({      
      name: new FormControl(this.data.name, [Validators.required]),
      state_id: new FormControl (this.data.state_id),
    });
  }

  update(){
    this.FormService.markFormGroupTouched(this.updateForm);
    if (this.updateForm.valid) {
      this.countryService.updateCity(this.updateForm.value, this.data.id).subscribe((res) => {
        this.dialogRef.close();
        console.log(res);
        this.toastr.success('City updated Successfully..!');
        this.route.navigate(['/list-city'+'/'+this.data.state_id]);
    },
      error => {
      console.log(error.error);    
      this.toastr.error(error.error.error.name);
      }); 
    }
    else {
      this.formErrors = this.FormService.validateForm(this.updateForm, this.formErrors, false)
    }
  }

  reset(){
    this.updateForm.reset();
  }
  close(){
    this.dialogRef.close();
  }

}