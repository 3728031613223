import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '../../../../node_modules/@angular/forms';
import { FormsService } from '../../shared/services/forms.service';
import { CountryService } from '../../shared/services/country.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '../../../../node_modules/@angular/material';
import { Router } from '../../../../node_modules/@angular/router';
import { ToastrService } from '../../../../node_modules/ngx-toastr';

@Component({
  selector: 'app-edit-country',
  templateUrl: './edit-country.component.html',
  styleUrls: ['./edit-country.component.css']
})
export class EditCountryComponent implements OnInit {

  editForm: FormGroup;
  
  public formErrors = {
    name: '',
    country_mobile_code: '',
  };

  constructor(
    private formBuilder: FormBuilder,
    public FormService: FormsService,
    private countryService: CountryService,
    private route: Router,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<EditCountryComponent>,
    @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit() {
    this.buildForm();
    console.log(this.data);
  }

  buildForm() {
    this.editForm = this.formBuilder.group({      
      name: new FormControl(this.data.name, [Validators.required]),
      country_mobile_code: new FormControl (this.data.country_mobile_code,[Validators.required]),
    });
  }

  update(){
    this.FormService.markFormGroupTouched(this.editForm);
    if (this.editForm.valid) {
      this.countryService.updateCountry(this.editForm.value, this.data.id).subscribe((res) => {
        this.dialogRef.close();
        console.log(res);
        this.toastr.success('Country updated Successfully..!');
        this.route.navigate(['/list-country']);
    },
      error => {
      console.log(error.error);    
      this.toastr.error(error.error.error.name);
      }); 
    }
    else {
      this.formErrors = this.FormService.validateForm(this.editForm, this.formErrors, false)
    }
  }

  reset(){
    this.editForm.reset();
  }
  close(){
    this.dialogRef.close();
  }

}

