import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment.prod';
import { HttpClient } from '../../../../node_modules/@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TherapeticService {

  constructor(private http: HttpClient) { }

  addTherapeticArea(formData){
    const url = environment.apiUrl + "";
    return this.http.post(url, formData );
  }
  updateTherapeticArea(formData, id){
    const url = environment.apiUrl + "/"+ id;
    return this.http.patch(url, formData );
  }
  getTherapeticArea(){
    const url = environment.apiUrl + "TherapeuticAreas";
    return this.http.get(url);
  }
}