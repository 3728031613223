import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { FormsService } from '../../shared/services/forms.service';
import { AuthService } from '../../shared/services/auth.service';
import { ResourceService } from '../../shared/services/resource.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AssignResourceComponent } from '../assign-resource/assign-resource.component';
import { User } from '../../shared/models/user.model';

@Component({
  selector: 'app-edit-resource',
  templateUrl: './edit-resource.component.html',
  styleUrls: ['./edit-resource.component.css']
})
export class EditResourceComponent implements OnInit {

  editResourceForm:FormGroup;
  loggedInUser: User;
  resource:any={};
  resourceTypes = [
    {value: 'CRO', viewValue: 'CRO'},
    {value: 'SITE', viewValue: 'SITE'},
    {value: 'SPONSOR', viewValue: 'SPONSOR'}
  ];

  public formErrors = {
    firstName: '',
    lastName:'',
    mobileNo:'',
    email:'',
    resourceAt:''
  };
  
  constructor( 
    private router: Router,
    public form: FormBuilder,
    public FormService: FormsService,
    private authService: AuthService,
    private resourceService: ResourceService,
    public dialogRef: MatDialogRef<EditResourceComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    ) { }

  ngOnInit() {
    this.resource = this.data;
  console.log(this.resource); 
  this.buildForm();
  this.loggedInUser = this.authService.getCurrentUser();
  
  //this.editResourceForm.setControl('firstName', new FormControl(this.data.firstName));

  }

  public buildForm() {
    this.editResourceForm = this.form.group({
      firstName: ['', [Validators.required]],
      lastName:['', [Validators.required]],
      mobileNo:['', [Validators.required]],
      email:['', [Validators.required, Validators.email]],
      resourceAt:['', [Validators.required]],
      status:['true'],
    });
    this.editResourceForm.valueChanges.subscribe((data) => {
      this.formErrors = this.FormService.validateForm(this.editResourceForm, this.formErrors, true)
    });
  }
  
  updateResource(){
      console.log(this.editResourceForm);
      
    this.FormService.markFormGroupTouched(this.editResourceForm);
    if (this.editResourceForm.valid) {
      this.editResourceForm.setControl('createdBy', new FormControl(this.loggedInUser.id));
      this.editResourceForm.setControl('id', new FormControl(this.resource.id));
      this.resourceService.updateResourceDetails(this.editResourceForm.value).subscribe((res) => {
        this.dialogRef.close();
      },
      error => {
        console.log(error.error.message);
      });

    }
    else {
      this.formErrors = this.FormService.validateForm(this.editResourceForm, this.formErrors, false)
    }
  }
  close(){
    this.dialogRef.close();
  }

}
