import { Component, OnInit } from '@angular/core';
import { SponsorService } from '../../shared/services/sponsor.service';
import { MatDialog } from '@angular/material';
import { AddSponsorComponent } from '../add-sponsor/add-sponsor.component';
import { NgxPaginationModule } from 'ngx-pagination';




@Component({
  selector: 'app-list-sponsor',
  templateUrl: './list-sponsor.component.html',
  styleUrls: ['./list-sponsor.component.css']
})
export class ListSponsorComponent implements OnInit {
  searchText: string;
  pageLimit = 10;
  pageLimitValues = [
    { value: 10 },
    { value: 20 },
    { value: 30 },
    { value: 40 },
  ];

  filteredItems: any = [];
  sponsorList: Object = [];

  constructor(private sponsorService: SponsorService, public dialog: MatDialog) { }

  ngOnInit() {
    this.loadsponsor();

  }

  pageLimitChange(pageLimitValue) {
    this.pageLimit = pageLimitValue;
  }
  assignfilterItems() {
    this.filteredItems = Object.assign([], this.sponsorList);
  }
  filterItem() {
    if (!this.searchText) this.assignfilterItems(); //when nothing has typed
    this.filteredItems = Object.assign([], this.sponsorList).filter(
      item => item.sponsorName.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1
    )
  }

  openDialog() {
    const dialogRef = this.dialog.open(AddSponsorComponent, {
      height: '600px',
      width: '980px'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      this.loadsponsor();
    });
  }


  loadsponsor() {
    this.sponsorService.list().subscribe((res) => {
      console.log(res);
      this.sponsorList = res;
      this.assignfilterItems();
    });
  }

}
