import { Component, OnInit } from '@angular/core';
import { AddRoleComponent } from '../add-role/add-role.component';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { RoleService } from '../../shared/services/role.service';

@Component({
  selector: 'app-list-role',
  templateUrl: './list-role.component.html',
  styleUrls: ['./list-role.component.css']
})
export class ListRoleComponent implements OnInit {

  pageLimit = 10;
  pageLimitValues = [
    { value: 10 },
    { value: 20 },
    { value: 30 },
    { value: 40 },
  ];
  searchText: string;
  filteredItems: any = [];
  rolelist: any = [];

  constructor(private dialog: MatDialog,private router: Router,private roleService: RoleService) { }

  ngOnInit() {
    this.getRoleList();
  }

  pageLimitChange(pageLimitValue) {
    this.pageLimit = pageLimitValue;
  }

  assignfilterItems() {
    this.filteredItems = Object.assign([], this.rolelist);
  }

  filterItem() {
    if (!this.searchText) this.assignfilterItems(); //when nothing has typed
    this.filteredItems = Object.assign([], this.rolelist).filter(
      item => item.name.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1
    )
  }

  openAddRole(){
    const dialogRef = this.dialog.open(AddRoleComponent,{
      width :  '980px',
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getRoleList();
    });
   }
  
   getRoleList() {
    this.roleService.getRole().subscribe((res) => {
      console.log(res);
      this.rolelist = res;
      this.assignfilterItems();
    });

  }
}
