import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from './auth-routing.module';
import { SigninComponent } from './signin/signin.component';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SignupComponent } from './signup/signup.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SetPasswordComponent } from './set-password/set-password.component';

@NgModule({
  imports: [
    CommonModule, FormsModule, AuthRoutingModule, ReactiveFormsModule,
    MatCardModule, MatInputModule, MatButtonModule, MatSnackBarModule
  ],
  declarations: [SigninComponent, SignupComponent, ResetPasswordComponent, SetPasswordComponent]
})
export class AuthModule { 
  constructor(){
  }
}
