import { Component, OnInit } from '@angular/core';
import { UserTemplateService } from '../../shared/services/user-template.service';
import { AuthService } from '../../shared/services/auth.service';
import { MatDialog, MatPaginator } from '@angular/material';
import { AssignReportComponent } from '../assign-report/assign-report.component';

@Component({
  selector: 'app-assigned-reports',
  templateUrl: './assigned-reports.component.html',
  styleUrls: ['./assigned-reports.component.css']
})
export class AssignedReportsComponent implements OnInit {

  constructor(private dialog: MatDialog,private authService: AuthService, private userTemplateService: UserTemplateService) { }
 
  searchText:string;
  pageLimit = 10;
  pageLimitValues = [
    { value: 5 },
    { value: 10 },
    { value: 20 },
    { value: 30 },
    { value: 40 },
  ];
  filteredItems:any =[];
  userId:Object;
  myReportList: any = [];


  ngOnInit() {
    this.userId = this.authService.getCurrentUser().id;
    this.loadMyReports();
  }

  loadMyReports(){
    this.userTemplateService.assignedlist(this.userId).subscribe ( (res) =>{
      console.log(res);      
      this.myReportList = res['templates'];   
      this.assignfilterItems();
    });
  }
  pageLimitChange(pageLimitValue){
    this.pageLimit = pageLimitValue;
  }
  assignfilterItems(){
    this.filteredItems = Object.assign([], this.myReportList);
  }
  filterItem(){
    if(!this.searchText) this.assignfilterItems(); //when nothing has typed
    this.filteredItems = Object.assign([], this.myReportList).filter(
       item => item.templateName.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1
    )
 }

  openDialog() {
    const dialogRef = this.dialog.open(AssignReportComponent,{
      height : 'auto',
      width :  '980px'
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      this.loadMyReports();     
    });
  }

}
