import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment.prod';

@Injectable()
export class CodeService {

 
  constructor(private http: HttpClient) { }

  getState(country) {

    const url = environment.apiUrl + "codesets/countries/" + country + "/states";
    return this.http.get(url);
  }
  getCountry() {
    const url = environment.apiUrl + "codesets/countries";
    return this.http.get(url);
  }

  getSiteRoles() {
    const url = environment.apiUrl + "codesets/SITE_ROLES";
    return this.http.get(url);
  }

  getQuestionTypes() {
    const url = environment.apiUrl + "codesets/QUESTION_TYPES";
    return this.http.get(url);
  }

  gettemplatetypes(){
    const url = environment.apiUrl + "codesets/TEMPLATE_TYPES";
    return this.http.get(url);
  }

  getCategories(templateType){
    const url = environment.apiUrl + "codesets/TEMPLATE_TYPES_" + templateType;
    return this.http.get(url);
  }

  getPhoneCode(countryCode){
    const url = environment.apiUrl+  "codesets/countries/"+ countryCode + "/telephoneCode";
    return this.http.get(url);
  }

  getVisitTypes(){
    const url = environment.apiUrl + "codesets/TYPE_OF_VISIT";
    return this.http.get(url);
  }

  getGenderTypes(){
    const url = environment.apiUrl + "codesets/GENDER";
    return this.http.get(url);
  }

}
