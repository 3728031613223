import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '../../../../node_modules/@angular/forms';
import { FormsService } from '../../shared/services/forms.service';
import { MatDialogRef } from '../../../../node_modules/@angular/material';
import { Router } from '../../../../node_modules/@angular/router';
import { ToastrService } from '../../../../node_modules/ngx-toastr';
import { TherapeticService } from '../../shared/services/therapetic.service';

@Component({
  selector: 'app-add-therapeutic-area',
  templateUrl: './add-therapeutic-area.component.html',
  styleUrls: ['./add-therapeutic-area.component.css']
})
export class AddTherapeuticAreaComponent implements OnInit {

  therapeuticForm: FormGroup;
  public formErrors = {
    name: '',
  };

  constructor(
    private formBuilder: FormBuilder,
    public FormService: FormsService,
    private therapeticService: TherapeticService,
    public dialogRef: MatDialogRef<AddTherapeuticAreaComponent>,
    private route: Router,
    private toastr: ToastrService) { }

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.therapeuticForm = this.formBuilder.group({
      name: new FormControl('', [Validators.required]),
    });
  }

  add(){
    this.FormService.markFormGroupTouched(this.therapeuticForm);
    if (this.therapeuticForm.valid) {
      this.therapeticService.addTherapeticArea(this.therapeuticForm.value).subscribe((res) => {
        this.dialogRef.close();
        console.log(res);
        this.toastr.success('TherapeticArea added Successfully..!');
        this.route.navigate(['/list-therapetic-area']);
    },
      error => {
      console.log(error.error);    
      this.toastr.error(error.error.error.name);
      }); 
    }
    else {
      this.formErrors = this.FormService.validateForm(this.therapeuticForm, this.formErrors, false)
    }
  }

  reset(){
    this.therapeuticForm.reset();
  }
  close(){
    this.dialogRef.close();
  }

}

