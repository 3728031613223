import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable()
export class UserTemplateService {

  constructor(private http: HttpClient) { }

  list(userId) {
    const url = environment.apiUrl + "usertemplates/users/" + userId;
    return this.http.get(url);
  }
  assignedlist(userId){
    const url = environment.apiUrl + "usertemplates/reviews/" + userId;
    return this.http.get(url);
  }


  findReportsForReview(userId) {
    const url = environment.apiUrl + "usertemplates/reviews/" + userId;
    return this.http.get(url);
  }

  save(formData) {
    const url = environment.apiUrl + "usertemplates";
    return this.http.post(url, formData);
  }


  getTemplateQuestions(userTemplateId) {
    const url = environment.apiUrl + "usertemplates/" + userTemplateId;
    return this.http.get(url);
  }
 

  submitAnswer(userTemplateId, ans) {
    console.log('submitAnswer:' + userTemplateId);
    const url = environment.apiUrl + "usertemplates/" + userTemplateId;
    return this.http.post(url, ans);
  }

  updateStatus(userTemplateId, status) {
    const url = environment.apiUrl + "usertemplates/" + userTemplateId + "/updateStatus/" + status;
    var obj =  {};
    return this.http.post(url,obj);
  }

}
