import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AuthGuard } from './auth.gurad';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule,  MatCheckboxModule, MatRadioModule, MatDatepickerModule, MatRippleModule, MatTooltipModule, MatIconModule,MatDialogModule, MatProgressBarModule, MatProgressSpinnerModule, MatNativeDateModule, MatDatepicker, MatListModule, MatDividerModule, MatMenuModule, MatGridListModule, MatSortModule } from '@angular/material';
import { MatSelectModule } from '@angular/material/select';
import { LayoutComponent } from './layout/layout.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HeaderComponent } from './layout/header/header.component';
import { SponsorComponent } from './sponsor/sponsor.component';
import { AddSponsorComponent } from './sponsor/add-sponsor/add-sponsor.component';
import { ListSponsorComponent } from './sponsor/list-sponsor/list-sponsor.component';
import { AuthLayoutComponent } from './auth-layout/auth-layout.component';
import { SponsorService } from './shared/services/sponsor.service';
import { CodeService } from './shared/services/code.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CustomValidators } from './shared/services/custom-validators.service';
import { FormsService } from './shared/services/forms.service';
import { AuthService } from './shared/services/auth.service';
import { ProjectsComponent } from './projects/projects.component';
import { ListProjectsComponent } from './projects/list-projects/list-projects.component';
import { ProjectService } from './shared/services/project.service';
import { ResourceComponent } from './resource/resource.component';
import { AddResourceComponent } from './resource/add-resource/add-resource.component';
import { ListResourceComponent } from './resource/list-resource/list-resource.component';
import { ResourceService } from './shared/services/resource.service';
import { MatPaginatorModule } from '@angular/material/paginator';
import { AssignResourceComponent } from './resource/assign-resource/assign-resource.component';
import { CroService } from './shared/services/cro.service';
import { EditResourceComponent } from './resource/edit-resource/edit-resource.component';
import { DeleteResourceComponent } from './resource/delete-resource/delete-resource.component';
import { AssignSitesComponent } from './projects/assign-sites/assign-sites.component';
import { QuestionService } from './shared/services/question.service';
import { ProjectTeamComponent } from './projects/project-team/project-team.component';
import { ProjectSiteListComponent } from './projects/project-site-list/project-site-list.component';
import { ProjectViewComponent } from './projects/project-view/project-view.component';
import { SitesComponent } from './sites/sites.component';
import { AddSiteComponent } from './sites/add-site/add-site.component';
import { ListSitesComponent } from './sites/list-sites/list-sites.component';
import { SiteService } from './shared/services/site.service';
import { CroComponent } from './cro/cro.component';
import { AddCroComponent } from './cro/add-cro/add-cro.component';
import { ListCroComponent } from './cro/list-cro/list-cro.component';
import { TemplateService } from './shared/services/template.service';
import { ProfileComponent } from './profile/profile.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { UserReportsComponent } from './user-reports/user-reports.component';
import { AssignedReportsComponent } from './user-reports/assigned-reports/assigned-reports.component';
import { AssignReportComponent } from './user-reports/assign-report/assign-report.component';
import { UserTemplateService } from './shared/services/user-template.service';
import { AlertModule, AlertService } from 'ngx-alerts';
import { EditQuestionsComponent } from './user-reports/edit-questions/edit-questions.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { MyReportsComponent } from './user-reports/my-reports/my-reports.component';
import { VerifyReportComponent } from './user-reports/verify-report/verify-report.component';
import { ViewReportsComponent } from './user-reports/view-reports/view-reports.component';
import { AddProjectComponent } from './projects/add-project/add-project.component';
import { AddCountryComponent } from './country/add-country/add-country.component';
import { ListCountyComponent } from './country/list-county/list-county.component';
import { ListStateComponent } from './state/list-state/list-state.component';
import { ListCityComponent } from './city/list-city/list-city.component';
import { ToastrModule } from 'ngx-toastr';
import { CountryService } from './shared/services/country.service';
import { AddCityComponent } from './city/add-city/add-city.component';
import { EditCityComponent } from './city/edit-city/edit-city.component';
import { EditStateComponent } from './state/edit-state/edit-state.component';
import { AddStateComponent } from './state/add-state/add-state.component';
import { EditCountryComponent } from './country/edit-country/edit-country.component';
import { EditRoleComponent } from './role/edit-role/edit-role.component';
import { AddRoleComponent } from './role/add-role/add-role.component';
import { ListRoleComponent } from './role/list-role/list-role.component';
import { ListTherapeuticAreaComponent } from './therapeutic-area/list-therapeutic-area/list-therapeutic-area.component';
import { AddTherapeuticAreaComponent } from './therapeutic-area/add-therapeutic-area/add-therapeutic-area.component';
import { EditTherapeuticAreaComponent } from './therapeutic-area/edit-therapeutic-area/edit-therapeutic-area.component';
import { AuthInterceptor } from './shared/interceptor/auth.interceptor';
import { RoleService } from './shared/services/role.service';
import { TherapeticService } from './shared/services/therapetic.service';





@NgModule({
  declarations: [
    AppComponent,
    AuthLayoutComponent,
    LayoutComponent,
    SidebarComponent,
    DashboardComponent,
    HeaderComponent,
    SponsorComponent,
    AddSponsorComponent,
    ListSponsorComponent,
    ProjectsComponent,
    ListProjectsComponent,
    ResourceComponent,
    AddResourceComponent,
    ListResourceComponent,
    AssignResourceComponent,
    EditResourceComponent,
    DeleteResourceComponent,
    AssignSitesComponent,
    ProjectTeamComponent,
    ProjectSiteListComponent,
    ProjectViewComponent,
    SitesComponent,
    AddSiteComponent,
    ListSitesComponent,
    CroComponent,
    AddCroComponent,
    ListCroComponent,
    ProfileComponent,
    UserReportsComponent,
    AssignedReportsComponent,
    AssignReportComponent,
    EditQuestionsComponent, 
    MyReportsComponent,
    VerifyReportComponent, 
    ViewReportsComponent, 
    AddProjectComponent, 
    AddCountryComponent, 
    ListCountyComponent, 
    ListStateComponent, 
    ListCityComponent, 
    AddCityComponent, 
    EditCityComponent, 
    EditStateComponent, 
    AddStateComponent, 
    EditCountryComponent, 
    EditRoleComponent, 
    AddRoleComponent, 
    ListRoleComponent, 
    ListTherapeuticAreaComponent, 
    AddTherapeuticAreaComponent, 
    EditTherapeuticAreaComponent,
    EditStateComponent,
    

    
  ],
  entryComponents: [
    SponsorComponent,
    AddSiteComponent,
    AddCroComponent,
    SponsorComponent,
    AddResourceComponent,
    AssignResourceComponent,
    EditResourceComponent,
    DeleteResourceComponent,
    AddResourceComponent,
    AssignReportComponent,
    VerifyReportComponent,
    AddCountryComponent,
    AddStateComponent,
    AddCityComponent,
    AddRoleComponent,
    AddTherapeuticAreaComponent,
    EditCountryComponent,
    EditStateComponent,
    EditCityComponent,
    EditRoleComponent,
    EditTherapeuticAreaComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatInputModule,
    FormsModule,
    MatRippleModule,
    ToastrModule.forRoot(
      {
        timeOut: 5000,
        positionClass: 'toast-bottom-right',
        preventDuplicates: true,
      }
    ),
    MatInputModule,
    MatTooltipModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatDialogModule,
    MatRadioModule,
    MatIconModule,
    MatMenuModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatDatepickerModule,                              
    MatNativeDateModule,
    MatPaginatorModule,
    MatDividerModule,
    MatListModule,
    NgxPaginationModule,
    MatRadioModule,
    MatGridListModule,
    NgxMatSelectSearchModule,
    Ng2SearchPipeModule,
    MatSortModule,
    AlertModule.forRoot({maxMessages: 5, timeout: 5000})


  ],
  providers: [
    AuthGuard,
    AlertService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
      },
    AuthService,
    SponsorService,
    CustomValidators,
    FormsService,
    CodeService,
    ProjectService,
    ResourceService,
    CroService,
    SiteService,
    QuestionService,
    TemplateService,
    UserTemplateService,  
    CountryService,
    RoleService,
    TherapeticService    
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
