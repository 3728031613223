import { Component, OnInit } from '@angular/core';
import { SiteService } from '../../shared/services/site.service';
import { MatDialog } from '@angular/material';
import { AddSiteComponent } from '../add-site/add-site.component';
import { NgxPaginationModule } from 'ngx-pagination';

@Component({
  selector: 'app-list-sites',
  templateUrl: './list-sites.component.html',
  styleUrls: ['./list-sites.component.css']
})
export class ListSitesComponent implements OnInit {

  siteList:Object = [];
  pageLimit = 10;
  filteredItems: any = [];
  searchText: string;
  pageLimitValues = [
    { value: 10 },
    { value: 20 },
    { value: 30 },
    { value: 40 },
  ];
  constructor(
    private dialog: MatDialog,
    private siteService: SiteService) { }

  ngOnInit() {
    this.loadsites();
  }

  pageLimitChange(pageLimitValue) {
    this.pageLimit = pageLimitValue;
  }

  assignfilterItems() {
    this.filteredItems = Object.assign([], this.siteList);
  }
  filterItem() {
    if (!this.searchText) this.assignfilterItems(); //when nothing has typed
    this.filteredItems = Object.assign([], this.siteList).filter(
      item => item.siteName.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1
    )
  }

  addSiteForm() {
    const dialogRef = this.dialog.open(AddSiteComponent,{
      height : '600px',
      width :  '980px'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      this.loadsites();
    });
  }

  loadsites() {
    this.siteService.list().subscribe((res) => {
      console.log(res);
      this.siteList = res;
      this.assignfilterItems();
    });
  }
  
}
