import { Component, OnInit } from '@angular/core';
import { User } from '../../shared/models/user.model';
import { AuthService } from '../../shared/services/auth.service';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { TemplateService } from '../../shared/services/template.service';
import { UserTemplateService } from '../../shared/services/user-template.service';
import { QuestionService } from '../../shared/services/question.service';

@Component({
  selector: 'app-view-reports',
  templateUrl: './view-reports.component.html',
  styleUrls: ['./view-reports.component.css']
})
export class ViewReportsComponent implements OnInit {


  templateId:Number;
  templateQuestions: any ={};
  template:any={};
  selectedQuestions=[];
  questions:any = {};
  userTemplateId: any;
  templateAnswer: any;
  id:Number;
  tempQuestions:any={};
  qlist:any = []
  templateReview = { 'status':''} ;

  constructor(private templateService: TemplateService, private UserTemplateService :UserTemplateService, private questionService: QuestionService, private router: Router,private route:ActivatedRoute,) {

    this.route.params.subscribe(params => {
      this.userTemplateId = params['id'];
     });
   }

   loadTemplateQuestions(){   
    this.UserTemplateService.getTemplateQuestions(this.userTemplateId).subscribe( (res) => {
    console.log(res);  
    this.templateQuestions = res;
   });  
   }

  ngOnInit() {
    this.loadTemplateQuestions();
  }

}
