import { Component, OnInit, Inject } from '@angular/core';
import { User } from '../../shared/models/user.model';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { FormsService } from '../../shared/services/forms.service';
import { AuthService } from '../../shared/services/auth.service';
import { CroService } from '../../shared/services/cro.service';
import { CodeService } from '../../shared/services/code.service';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-add-cro',
  templateUrl: './add-cro.component.html',
  styleUrls: ['./add-cro.component.css']
})
export class AddCroComponent implements OnInit {

  phoneCode: any;
  loggedInUser: User;
  croForm:FormGroup;
  countries:Object = [];
  states:Object = [];

  constructor(
    public form: FormBuilder,
    public FormService: FormsService,
    private authService: AuthService,
    private croService: CroService,
    private codeService:CodeService,
    private router: Router,
    public dialogRef: MatDialogRef<AddCroComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  public formErrors = {
    croName: '',
    croNo: '',
    addressLine1: '',
    addressLine2: '',
    country: '',
    stateCode:'',
    zipCode:'',
    personName:'',
    countryTelephoneCode:'',
    telephoneNo:'',
    mobileNo:'',
    email:'',
  };
  

  ngOnInit() {
    this.buildForm();
    this.loadCountries();
    this.loggedInUser = this.authService.getCurrentUser();
  }

  public buildForm() {
    this.croForm = this.form.group({
    croName: ['', [Validators.required]],
    croNo: ['', [Validators.required]],
    address : this.form.group({
    addressLine1: ['', [Validators.required]],
    addressLine2: ['', [Validators.required]],
    countryCode: ['', [Validators.required]],
    stateCode: ['', [Validators.required]],
    zipCode: ['', [Validators.required]],
     }),
    contact : this.form.group({
      personName: ['', [Validators.required]],
      countryTelephoneCode: ['', [Validators.required]],
      telephoneNo: ['', [Validators.required]],
      mobileNo: ['', [Validators.required]],
      email: ['', [Validators.required]],
      })
    });

  // on each value change we call the validateForm function
  // We only validate form controls that are dirty, meaning they are touched
  // the result is passed to the formErrors object
    this.croForm.valueChanges.subscribe((data) => {
    this.formErrors = this.FormService.validateForm(this.croForm, this.formErrors, true)
  });
}

  addCRO() {
    this.FormService.markFormGroupTouched(this.croForm);
    if (this.croForm.valid) {
      this.croForm.setControl('createdBy', new FormControl(this.loggedInUser.id));
      console.log(this.croForm.value);
      this.croService.addCro(this.croForm.value).subscribe((res) => {
      this.croForm.reset();
      this.dialogRef.close();
      
    },
      error => {
      console.log(error.error.message);
      //this.alertService.danger(error.error.message);
      }); 
    }
    else {
      this.formErrors = this.FormService.validateForm(this.croForm, this.formErrors, false)
    }
  }

  loadCountries(){
    this.codeService.getCountry().subscribe ( (res) =>{
      this.countries = res;
      console.log(res);
    });
  }

  selectedCountry(countryName){
    console.log('load states:' + countryName);
    this.codeService.getState(countryName).subscribe( (res) =>{
      this.states = res;
      console.log(res);
    });
    this.codeService.getPhoneCode(countryName).subscribe( (res) =>{
      this.phoneCode = res[0].description;
      this.phoneCode='+' + this.phoneCode;
      console.log(res[0].description);
    });
  }

  



  resetForm(){
    this.croForm.reset();
  }

  close(){
    this.dialogRef.close();
  }


}
