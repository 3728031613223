import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    userToken:any ={};
    
    constructor() {
        
     }
     getUserDetails(){
        this.userToken = JSON.parse(localStorage.getItem('userDetails'));
     }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      this.getUserDetails();
      if(req.url!= environment.apiUrl + 'AppUsers/login' ){
      req = req.clone({
      setHeaders: {
        'Content-Type' : 'application/json; charset=utf-8',
        'Accept'       : 'application/json',
        'Authorization':  this.userToken.id
      },
    });
    return next.handle(req);
}
    //`Bearer ${this.userToken.id}`
    //'Bearer ' + this.userToken.id,
    return next.handle(req);
  }
}