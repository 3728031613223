import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { CustomValidators } from '../../shared/services/custom-validators.service';
import { FormsService } from '../../shared/services/forms.service';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../shared/services/auth.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  public resetForm: FormGroup;
  public formErrors = {
    email: ''
  };

  constructor(
    public form: FormBuilder,
    public FormService: FormsService,
    public snackbar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
  ) { }

  ngOnInit() {
    this.buildForm();
  }

  reSet() {
    this.FormService.markFormGroupTouched(this.resetForm);
    if (this.resetForm.valid) {
      this.authService.resetPassword(this.resetForm.value)
        .subscribe(
          data => {
            this.router.navigate(['/login']);
          },
          error => {
            console.log(error);
            this.snackbar.open('Error!', 'Close', {
              duration: 3000,
            });
          });
      //this.signUpForm.reset();
    } else {
      this.formErrors = this.FormService.validateForm(this.resetForm, this.formErrors, false)
    }
  }

  buildForm() {
    this.resetForm = this.form.group({
      email: ['', [Validators.required, Validators.email]]
    });
    this.resetForm.valueChanges.subscribe((data) => {
      this.formErrors = this.FormService.validateForm(this.resetForm, this.formErrors, true)
    });
  }

}
