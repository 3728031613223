import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { CustomValidators } from '../../shared/services/custom-validators.service';
import { FormsService } from '../../shared/services/forms.service';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../shared/services/auth.service';


@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class SigninComponent implements OnInit {

  public signInForm: FormGroup;
  public formErrors = {
    email: '',
    password: '',
  };
  
  constructor(
    public form: FormBuilder,
    public FormService: FormsService,
    public snackbar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,


  ) {}
  
  signIn() {
    this.FormService.markFormGroupTouched(this.signInForm);
    if (this.signInForm.valid) {
      this.authService.login(this.signInForm.value)
      .subscribe(
        data => {
          let redirect = this.authService.redirectUrl ? this.authService.redirectUrl : '/dashboard';
          this.router.navigate([redirect]);           
        },
        error => {

          console.log(error);
          if  (error.error.message === "Account is Inactive" )
          {
            this.router.navigate(['forget-password']);
          }
          else
          {
            this.snackbar.open('Error!', 'Close', {
              duration: 3000,
            });
          }
        });
      //this.signUpForm.reset();
    } else {
      this.formErrors = this.FormService.validateForm(this.signInForm, this.formErrors, false)
    }
  }
  
  // build the user edit form
  public buildForm() {
    this.signInForm = this.form.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });

    // on each value change we call the validateForm function
    // We only validate form controls that are dirty, meaning they are touched
    // the result is passed to the formErrors object
    this.signInForm.valueChanges.subscribe((data) => {
      this.formErrors = this.FormService.validateForm(this.signInForm, this.formErrors, true)
    });
  }

  // initiate component
  ngOnInit() {
    this.buildForm();
    if (this.authService.isLoggedIn()) {
      console.log("Already Login");
      let redirect = this.authService.redirectUrl ? this.authService.redirectUrl : '/dashboard';
      this.router.navigate([redirect]);   
    }
    else{
      this.router.navigate(['/login']);
    }
  }

}
