import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '../../../../node_modules/@angular/forms';
import { FormsService } from '../../shared/services/forms.service';
import { CountryService } from '../../shared/services/country.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '../../../../node_modules/@angular/material';
import { ToastrService } from '../../../../node_modules/ngx-toastr';
import { Router } from '../../../../node_modules/@angular/router';

@Component({
  selector: 'app-add-state',
  templateUrl: './add-state.component.html',
  styleUrls: ['./add-state.component.css']
})
export class AddStateComponent implements OnInit {

  stateForm: FormGroup;
  public formErrors = {
    name: '',
    country_id:'',
  };

  constructor(
    private formBuilder: FormBuilder,
    public FormService: FormsService,
    private countryService: CountryService,
    public dialogRef: MatDialogRef<AddStateComponent>,
    private route: Router,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.stateForm = this.formBuilder.group({      
      name: new FormControl('', [Validators.required]),
      country_id: new FormControl(this.data),
    });
  }

  add(){
    this.FormService.markFormGroupTouched(this.stateForm);
    if (this.stateForm.valid) {
      this.countryService.addState(this.stateForm.value).subscribe((res) => {
        this.dialogRef.close();
        console.log(res);
        this.toastr.success('State added Successfully..!');
        this.route.navigate(['/list-state'+'/'+this.data]);
    },
      error => {
      console.log(error.error);    
      this.toastr.error(error.error.error.name);
      }); 
    }
    else {
      this.formErrors = this.FormService.validateForm(this.stateForm, this.formErrors, false)
    }
  }

  reset(){
    this.stateForm.reset();
  }
  close(){
    this.dialogRef.close();
  }

}
