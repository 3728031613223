import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cro',
  templateUrl: './cro.component.html',
  styleUrls: ['./cro.component.css']
})
export class CroComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
