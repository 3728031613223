export class User {
    id:number;
    name:string;
    email:string;
    password:string;
    role:string;
    active:boolean;

    constructor(id:number, name:string,email:string, active:boolean){
        this.id = id;
        this.name = name;
        this.email = email;
        this.active = active;
    }
    
}