import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable()
export class SponsorService {

 
  constructor(private http :HttpClient) { }

  save(formData){
    const url = environment.apiUrl + "sponsors";
    return this.http.post(url, formData );
  }


  list(){
    const url = environment.apiUrl + "sponsors";
    return this.http.get(url);
  }

}
