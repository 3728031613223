import { Component, OnInit, ViewChild } from '@angular/core';
import { AddResourceComponent } from '../add-resource/add-resource.component';
import { MatDialog, MatPaginator, Sort } from '@angular/material';
import { ResourceService } from '../../shared/services/resource.service';
import { AssignResourceComponent } from '../assign-resource/assign-resource.component';
import { EditResourceComponent } from '../edit-resource/edit-resource.component';
import { DeleteResourceComponent } from '../delete-resource/delete-resource.component';


@Component({
  selector: 'app-list-resource',
  templateUrl: './list-resource.component.html',
  styleUrls: ['./list-resource.component.css']
})
export class ListResourceComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;

  searchText:string;
  pageLimit = 10;
  pageLimitValues = [
    { value: 5 },
    { value: 10 },
    { value: 20 },
    { value: 30 },
    { value: 40 },
  ];
  filteredItems:any =[];
  resourcelist : any = [];
  sortedData;
  constructor(private dialog: MatDialog, private resourceService : ResourceService) {
    this.sortedData = this.filteredItems.slice();
   }
  //  sortData(sort: Sort) {
  //   const data = this.filteredItems.slice();
  //   if (!sort.active || sort.direction == '') {
  //     this.sortedData = data;
  //     return;
  //   }

  //   this.sortedData = data.sort((a, b) => {
  //     let isAsc = sort.direction == 'asc';
  //     switch (sort.active) {
  //       case 'name': return compare(a.name, b.name, isAsc);
  //       case 'calories': return compare(+a.calories, +b.calories, isAsc);
  //       case 'fat': return compare(+a.fat, +b.fat, isAsc);
  //       case 'carbs': return compare(+a.carbs, +b.carbs, isAsc);
  //       case 'protein': return compare(+a.protein, +b.protein, isAsc);
  //       default: return 0;
  //     }
  //   });
  // }
  ngOnInit() {
  this.loadResources();
  }

  loadResources() {
    this.resourceService.list().subscribe((res) => {
      console.log(res);
      this.resourcelist = res;
      this.assignfilterItems();
    });
  }
  pageLimitChange(pageLimitValue){
    this.pageLimit = pageLimitValue;
  }
  assignfilterItems(){
    this.filteredItems = Object.assign([], this.resourcelist);
  }
  filterItem(){
    if(!this.searchText) this.assignfilterItems(); //when nothing has typed
    this.filteredItems = Object.assign([], this.resourcelist).filter(
       item => item.firstName.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1
    )
 }

  openAddResource() {
    const dialogRef = this.dialog.open(AddResourceComponent,{
      
      width :  '980px'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      this.loadResources();
    });
  }

  openAssignResource() {
    const dialogRef = this.dialog.open(AssignResourceComponent,{
      
      width :  '980px'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  openEditResource(resourceDetails) {
    const dialogRef = this.dialog.open(EditResourceComponent,{
      
      width :  '980px',
      data: resourceDetails
    });

    dialogRef.afterClosed().subscribe(result => {
      this.loadResources();
    });
  }

  openDeleteResource(resourceDetails) {
    const dialogRef = this.dialog.open(DeleteResourceComponent,{
    
      width :  '980px',
      data: resourceDetails
    });

    dialogRef.afterClosed().subscribe(result => {
      this.loadResources();
    });
  }

  


}



