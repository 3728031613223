import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder,FormGroup, FormControl, Validators } from '@angular/forms';
import { CustomValidators } from '../../shared/services/custom-validators.service';
import { FormsService } from '../../shared/services/forms.service';
import { SponsorService } from '../../shared/services/sponsor.service';
import { CodeService } from '../../shared/services/code.service';
import { Router } from '@angular/router';
import { User } from '../../shared/models/user.model';
import { AuthService } from '../../shared/services/auth.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-add-sponsor',
  templateUrl: './add-sponsor.component.html',
  styleUrls: ['./add-sponsor.component.css']
})
export class AddSponsorComponent implements OnInit {

  
  
  countries:any = [];
  states:any = [];
  countryName: String;
  sponsor:any= {};
  createdBy: any = {};  
  phoneCode: String;
  

sponsorForm: FormGroup;


  public formErrors = {
    sponsorName: '',
    sponsorNo: '',
    addressLine1: '',
    addressLine2: '',
    country: '',
    stateCode:'',
    zipCode:'',
    personName:'',
    countryTelephoneCode:'',
    telephoneNo:'',
    mobileNo:'',
    email:'',
  };
  
 
  loggedInUser: User;
  constructor(public form: FormBuilder,
    public FormService: FormsService,
    public codeService: CodeService,
    public router: Router,
    public sponsorService: SponsorService,
    private authService: AuthService,
    public dialogRef: MatDialogRef<AddSponsorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
 ) { }

  ngOnInit() {
    this.loadCountries();
    this.buildForm();
    this.loggedInUser = this.authService.getCurrentUser();
  }

  loadCountries(){
    this.codeService.getCountry().subscribe ( (res) =>{
      this.countries = res;
      console.log(res);
    });
  }

  selectedCountry(countryName){
    console.log('load states:' + countryName);
    this.codeService.getState(countryName).subscribe( (res) =>{
      this.states = res;
      console.log(res);
    });

    this.codeService.getPhoneCode(countryName).subscribe( (res) =>{
      this.phoneCode = res[0].description;
      this.phoneCode='+' + this.phoneCode;
      console.log(res[0].description);
    });
  }
    
  addSponsor() { 
    console.log(this.sponsorForm.value);
    this.FormService.markFormGroupTouched(this.sponsorForm); 
    this.sponsorForm.setControl('createdBy', new FormControl(this.loggedInUser.id));
       if (this.sponsorForm.valid) {
       this.sponsorService.save(this.sponsorForm.value).subscribe((res) => {
        this.sponsorForm.reset();
        this.dialogRef.close(); 
      });
    } else {
      this.formErrors = this.FormService.validateForm(this.sponsorForm, this.formErrors, false)
    }   
}    


       public buildForm() {
        this.sponsorForm = this.form.group({
        sponsorName: ['', [Validators.required]],
        sponsorNo: ['', [Validators.required]],
        address : this.form.group({
        addressLine1: ['', [Validators.required]],
        addressLine2: ['', [Validators.required]],
        countryCode: ['', [Validators.required]],
        stateCode: ['', [Validators.required]],
        zipCode: ['', [Validators.required]],
         }),
        contact : this.form.group({
          personName: ['', [Validators.required]],
          countryTelephoneCode: ['', [Validators.required]],
          telephoneNo: ['', [Validators.required]],
          mobileNo: ['', [Validators.required]],
          email: ['', [Validators.required]],
          })
        });
  
      // on each value change we call the validateForm function
      // We only validate form controls that are dirty, meaning they are touched
      // the result is passed to the formErrors object
        this.sponsorForm.valueChanges.subscribe((data) => {
        this.formErrors = this.FormService.validateForm(this.sponsorForm, this.formErrors, true)
      });
    }
  
    close(){
      this.dialogRef.close();
    }
}
