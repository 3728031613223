import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SigninComponent } from './signin/signin.component';
import { SignupComponent } from './signup/signup.component';
import { SetPasswordComponent } from './set-password/set-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';


const routes: Routes = [
  
    { path: '', component: SigninComponent },
    { path: 'login', component: SigninComponent },
    { path: 'register', component: SignupComponent },
    { path: 'activate/:activationKey', component: SetPasswordComponent },
    { path: 'reset-password', component: ResetPasswordComponent },
    
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { 
  constructor(){
    console.log('AuthRoutingModule');
  }
}
