import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-assign-sites',
  templateUrl: './assign-sites.component.html',
  styleUrls: ['./assign-sites.component.css']
})
export class AssignSitesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
