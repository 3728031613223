import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable()
export class TemplateService {

  constructor(private http: HttpClient) { }

  createTemplate(templateObj){
    const url = environment.apiUrl + "templates";
    return this.http.post(url,templateObj);
  }

  list(){
    const url = environment.apiUrl + "templates";
    return this.http.get(url);
  }
 
  findTemplatesForReview(){
    const status = "SUBMITTED";
    const url = environment.apiUrl + "templates/status/" + status;
    return this.http.get(url);
  }

  getTemplateQuestions(templateId){
    const url = environment.apiUrl +"templates/" + templateId +"/questions";
    return this.http.get(url);
  }

  getTemplateSectionAnswer(templateId){
    const url = environment.apiUrl +"usertemplates/" + templateId +"/sections";
    return this.http.get(url);
  }
  
  getTemplateSectionQuestions(templateId){
    const url = environment.apiUrl +"templates/" + templateId +"/questions";
    return this.http.get(url);
  }

  getTemplate(id){
    const url = environment.apiUrl +"templates/" + id;
    return this.http.get(url);
  }

  updateStatus(id,formData){
    var config = {headers : {
      "Content-Type": "application/json; charset = utf-8;"
  }
}; 
    const url = environment.apiUrl + "templates/"+ id + "/updateStatus";
    return this.http.post(url, JSON.stringify(formData), config);
  }

  assignQuestions(templateId,questions){
    const url = environment.apiUrl +"templates/" + templateId + "/questions";
    return this.http.post(url,questions );
  }
  

}
