import { Component, OnInit } from '@angular/core';
import  PerfectScrollbar  from 'perfect-scrollbar';
declare const $: any;
export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  collapse?: string;
  children?: ChildrenItems[];
}

export interface ChildrenItems {
  path: string;
  title: string;
  ab: string;
  type?: string;
}

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  menuItems = [];
  menu = [
    {
      path: '/dashboard',
      title: 'Dashboard',
      type: 'link',
      icontype: 'dashboard'
    },
    {
      path: '/sponsor',
      title: 'Sponsor',
      type: 'link',
      icontype: 'dashboard'
    },
    {
      path: '/cro',
      title: 'CRO',
      type: 'link',
      icontype: 'dashboard'
    },
    {
      path: '/resource',
      title: 'Resource',
      type: 'link',
      icontype: 'dashboard'
    },
   
    {
      path: '/list-country',
      title: 'Countries',
      type: 'link',
      icontype: 'dashboard'
    },
         
    {
      path: '/list-role',
      title: 'Roles',
      type: 'link',
      icontype: 'dashboard'
    },
    
    {
      path: '/list-therapeutic-area',
      title: 'Therapeutic areas',
      type: 'link',
      icontype: 'dashboard'
    },
   
    
    
  ];

  constructor() { }

  ngOnInit() {
    this.menuItems = this.menu;
  }
  

  isMobileMenu() {
    if ($(window).width() > 991) {
        return false;
    }
    return true;
};

updatePS(): void  {
  if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
      let ps = new PerfectScrollbar(elemSidebar, { wheelSpeed: 2, suppressScrollX: true });
  }
}
isMac(): boolean {
  let bool = false;
  if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
      bool = true;
  }
  return bool;
}

}
