import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material';
import { AddCountryComponent } from '../add-country/add-country.component';
import { Router } from '@angular/router';
import { CountryService } from '../../shared/services/country.service';
import { EditCountryComponent } from '../edit-country/edit-country.component';

@Component({
  selector: 'app-list-county',
  templateUrl: './list-county.component.html',
  styleUrls: ['./list-county.component.css']
})
export class ListCountyComponent implements OnInit {

  pageLimit = 10;
  pageLimitValues = [
    { value: 10 },
    { value: 20 },
    { value: 30 },
    { value: 40 },
  ];
  searchText: string;
  filteredItems: any = [];
  countrylist: any = [];

  constructor(private dialog: MatDialog,private router: Router,private countryService: CountryService) { }

  ngOnInit() {
    this.getCountryList();
  }

  pageLimitChange(pageLimitValue) {
    this.pageLimit = pageLimitValue;
  }

  assignfilterItems() {
    this.filteredItems = Object.assign([], this.countrylist);
  }

  filterItem() {
    if (!this.searchText) this.assignfilterItems(); //when nothing has typed
    this.filteredItems = Object.assign([], this.countrylist).filter(
      item => item.croName.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1
    )
  }

  openAddCountry(){
    const dialogRef = this.dialog.open(AddCountryComponent,{
      width :  '980px',
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getCountryList();
    });
   }

   openUpdateCountry(c){
    const dialogRef = this.dialog.open(EditCountryComponent,{
      width :  '980px',
      data:c
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getCountryList();
    });
   }
  
   getCountryList() {
    this.countryService.getCountry().subscribe((res) => {
      console.log(res);
      this.countrylist = res;
      this.assignfilterItems();
    });
  }

  getStateByCountry(id){
    console.log("id", id);
    this.router.navigate(['/list-state/'+id]);
  }
}
