import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class QuestionService {

  constructor(private http:HttpClient) { }

  list(){
    const url = environment.apiUrl + "questions";
    return this.http.get(url);
  }
  
  addQuestion(formData){
    const url = environment.apiUrl + "questions";
    return this.http.post(url, formData);
  }

  findById(id){
    const url = environment.apiUrl + "questions/" + id;
    return this.http.get(url);
  }

}
