import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment.prod';

@Injectable()
export class ResourceService {

  constructor(private http: HttpClient) { }

  addresourceDetails(formData){
    const url = environment.apiUrl + "userdetails";
    return this.http.post(url, formData );
  }

  list(){
    const url = environment.apiUrl + "userdetails";
    return this.http.get(url);
  }
  updateResourceDetails(formData){
    const url = environment.apiUrl + "userdetails/"+ formData.id;
    return this.http.post(url,formData);
  }
  deleteResourceDetails(id){
    
    const url = environment.apiUrl + "userdetails/"+id;
    return this.http.delete(url);
  }

}
