import { Component, OnInit, Input } from '@angular/core';
import { ProjectService } from '../../shared/services/project.service';
import { MatDialog } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../shared/services/auth.service';
@Component({
  selector: 'app-project-site-list',
  templateUrl: './project-site-list.component.html',
  styleUrls: ['./project-site-list.component.css']
})
export class ProjectSiteListComponent implements OnInit {

  loggedInUser:any = {};
  assignedSites:any = [];
  projectId:number;

  constructor(
    private projectService: ProjectService,
    private route:ActivatedRoute,
    private router: Router,  
    private authService: AuthService,
  ) { 

    route.params.subscribe ( (params) =>{
      this.projectId = params['id'];
      console.log("projectId:" + this.projectId );
    });
  }

  ngOnInit() {
    this.loggedInUser = this.authService.getCurrentUser();
    console.log("Project Site Component:");
    console.log(this.projectId);
    this.loadAssignedSitesList(this.projectId);
  }


  loadAssignedSitesList(projectId)
  {
    this.projectService.getAssignedSitesList(projectId).subscribe((res) => {
      this.assignedSites = res;
      console.log(this.assignedSites);
    });
  }

  

}
