import { Component, OnInit } from '@angular/core';
import { CroService } from '../../shared/services/cro.service';
import { AddCroComponent } from '../add-cro/add-cro.component';
import { MatDialog } from '@angular/material';
import { NgxPaginationModule } from 'ngx-pagination';

@Component({
  selector: 'app-list-cro',
  templateUrl: './list-cro.component.html',
  styleUrls: ['./list-cro.component.css']
})
export class ListCroComponent implements OnInit {

  pageLimit = 10;
  pageLimitValues = [
    { value: 10 },
    { value: 20 },
    { value: 30 },
    { value: 40 },
  ];
  searchText: string;
  filteredItems: any = [];
  croList: Object = [];
  templates: Object = [];

  constructor(
    private dialog: MatDialog,
    private croService: CroService) { }

  ngOnInit() {
    this.loadCRO();
  }

  pageLimitChange(pageLimitValue) {
    this.pageLimit = pageLimitValue;
  }

  assignfilterItems() {
    this.filteredItems = Object.assign([], this.croList);
  }
  filterItem() {
    if (!this.searchText) this.assignfilterItems(); //when nothing has typed
    this.filteredItems = Object.assign([], this.croList).filter(
      item => item.croName.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1
    )
  }

  addCroForm() {
    const dialogRef = this.dialog.open(AddCroComponent, {
      height: '600px',
      width: '980px'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      this.loadCRO();
    });
  }


  loadCRO() {
    this.croService.list().subscribe((res) => {
      console.log(res);
      this.croList = res;
      this.assignfilterItems();
    });
  }
}
